import React, { useState } from 'react';
import useWebsocketWrapper from './UseWebSocket'; // Assuming this is your custom hook for WebSocket
import { TextField, Button, List, ListItem, ListItemText, Paper, Box, useTheme } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { tokens } from "../../theme";
import {ReadyState } from 'react-use-websocket';




const Chatbot = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);    
  const [userInput, setUserInput] = useState('');
  const { messages, sendMessage, readyState } = useWebsocketWrapper('ws://localhost:8000/');
  console.log(messages)

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (userInput.trim()) {
      sendMessage(JSON.stringify({ text: userInput }));
      setUserInput('');
    }
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return (
    <Box sx={{ 
        width: 'calc(100vw - 700px)',
        backgroundColor: colors.primary[200], 
        marginRight: '10px',
        marginLeft: '10px', 
        marginBottom: '20px',}}>  
    <List>
          {messages.map((msg, index) => (
            <ListItem key={index} style={{
                backgroundColor: (colors.primary[300]),
                borderRadius: '10px',
                marginBottom: '10px',
              }}>
              <ListItemText primary={msg.text.msg} style={{
                color: (colors.grey[100])
              }} />
            </ListItem>
          ))}
    </List>
      <form onSubmit={handleSubmit} style={{ display: 'flex',
                                            backgroundColor: colors.primary[100],
                                            borderRadius: '15px'  }}>
        <TextField
          label="Type your message"
          variant="outlined"
          fullWidth
          value={userInput}
          onChange={handleUserInput}
          sx={{
            borderRadius: '15px', 
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '15px', 
            }}}
         InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton 
                  color="primary" 
                  onClick={handleSubmit} 
                >
                  <SendIcon style={{ color: colors.grey[300]}} /> 
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Box>
  );
};

const ChatContainer = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);  
    return (
      <Box sx={{
        marginRight: '25px',
        marginLeft: '25px',
        marginBottom: '50px',
        marginTop: '70px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end', 
        borderRadius: '15px',
        height: 'calc(100vh - 60px)',
        width: 'calc(100vw - 250px)',
        backgroundColor: (colors.primary[200])
      }}>
        <Chatbot />
      </Box>
    );
  };
  
export default ChatContainer;

