import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ component: Component }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    isAuthenticated
      ? <Component />
      : <Navigate to="/login" replace />
  );
};

export default ProtectedRoute;