import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#FFFFFF",
          200: "#ECECF1",
          300: "#D7D7D7",
          400: "#9A9B9F",
        },
        primary: {
          100: "#1D242C",
          200: "#343E4A",
          300: "#47586B",
        },
        orangeAccent: {
          100: "#FEB088",
          200: "#FF8748",
          300: "#D4500B",
        },
      }
    : {      
        grey: {
            100: "#FFFFFF",
            200: "#ECECF1",
            300: "#D7D7D7",
            400: "#9A9B9F",
          },
          primary: {
            100: "#1D242C",
            200: "#343E4A",
            300: "#47586B",
          },
        orangeAccent: {
            100: "#FEB088",
            200: "#FF8748",
            300: "#D4500B",
          },
      }),
});

export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
        mode: mode,
        ...(mode === "dark"
          ? {
            primary: {
                main: colors.grey[300],
              },
              secondary: {
                main: colors.orangeAccent[200],
              },
              background: {
                paper: colors.primary[100],
                default: colors.primary[100],
              },
            }
          : {
              primary: {
                main: colors.primary[100],
              },
              secondary: {
                main: colors.orangeAccent[200],
              },
              background: {
                default: colors.grey[100],
                paper: colors.primary[100],
              },
            }),
      },
    typography: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");
  const colorMode = "dark"

  /*const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );*/

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
