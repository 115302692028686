import React from 'react';
import Landing from './landing/components/Landing';
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import GoogleSocialAuth from './auth/components/Login';
import {Route, Routes, BrowserRouter as Router} from 'react-router-dom'
import { AuthProvider } from './auth/components/AuthContext'; 
import ProtectedRoute from './auth/components/ProtectedRoute'; 

function App() {
  const [theme, colorMode] = useMode();
    return (
      <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
      <CssBaseline />  
        <div className="app">
          <AuthProvider>
            <Router>
              <Routes>
                <Route exact path={'/login'} element={<GoogleSocialAuth/>} /> 
                <Route path="/" element={<ProtectedRoute component={Landing} />} />
              </Routes>
            </Router>
          </AuthProvider>
        </div>
      </ThemeProvider>
      </ColorModeContext.Provider>
    );
}

export default App;
