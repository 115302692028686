import {AppSidebar, LogoBox } from './AppSidebar';
import ChatContainer  from '../../Chatbox/components/Chatbot';
import { Box, useTheme } from '@mui/material';
import { tokens } from "../../theme";

function Landing() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);  
      return (
        <div className="landing">
            <Box sx={{
                width: '100vw', 
                height: '100vh',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: (colors.primary[100])
                }}>
                <Box sx={{
                 display: 'flex',
                 flexDirection: 'column',                   
                }}>
                    <LogoBox/>
                    <AppSidebar />
                </Box>
                <ChatContainer />
            </Box>
        </div>
      );
  }
  
export default Landing;