import { GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import { GOOGLE_CLIENT_ID, BACKEND_URL } from '../../config';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect, useContext } from "react";
import { AuthContext } from './AuthContext'; 
import { useNavigate } from 'react-router-dom'; 

const GoggleLoginApp = () => {
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const loginURL = BACKEND_URL + 'google-login/'
  const { handleAuthentication } = useContext(AuthContext);
  const navigate = useNavigate(); 

  const handleLoginSuccess = async (credentialResponse) => {
    setIsAuthorizing(true);
    try {
      console.log("Sending credentials to server:")
      const response = await axios.post(loginURL, credentialResponse);
      if (response.status === 200  && 
          response.data.access_token &&
          response.data.refresh_token ) {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        console.log("User Authorized", response)
        handleAuthentication(true);
        navigate('/');  
      } else {
        alert('Authorization failed. Please login again.');
      }
    } catch (error) {
      console.error('Server error:', error);
      alert('Authorization failed. Please login again.');
    } finally {
      setIsAuthorizing(false);
    }
  };

  const handleLoginError = () => {
    console.log('Login Failed');
    alert('Login Failed. Please try again.');
  };

  useEffect(() => {
    setDialogOpen(isAuthorizing);
  }, [isAuthorizing]);

  return (
    <div className="goggleloginapp">
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <GoogleLogin
          onSuccess={credentialResponse => {
            console.log(credentialResponse);
            handleLoginSuccess(credentialResponse)
          }}
        
          onError={() => {
            console.log('Login Failed');
            handleLoginError()
          }}
          useOneTap
        />
      </GoogleOAuthProvider>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>
          Authorizing...
        </DialogTitle>
      </Dialog>
    </div>
);
}

export default GoggleLoginApp;

  
  