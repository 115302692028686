import { useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

const useWebsocketWrapper = (url) => {
  const [messages, setMessages] = useState([]);

  const { sendMessage, readyState } = useWebSocket(url, {
    onOpen: () => console.log("Connected!"),
    onClose: () => console.log("Disconnected!"),
    onMessage: (e) => {
      const message = JSON.parse(e.data);
      setMessages((prevMessages) => [...prevMessages, message]);
    },
    onError: (error) => console.error('WebSocket error:', error),
    shouldReconnect: (closeEvent) => true, // Automatically reconnect
  });

  return { messages, sendMessage, readyState };
};

export default useWebsocketWrapper;