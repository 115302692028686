import React from 'react';
import { tokens } from "../../theme";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Divider from '@mui/material/Divider';
import logo from '../../assets/logo.png';

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useState } from "react";

export function LogoBox() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="5px" 
         backgroundColor={colors.primary[100]}
          width="250px" >
      
      <Box 
        display="flex" 
        justifyContent="flex-start" 
        alignItems="center"
        position="relative"
        backgroundColor={colors.primary[100]}
        width="75px" 
      >
        <Box 
          component="img" 
          src={logo} 
          alt="Skingleam"
          width="75px" 
          height="55px"
          position="absolute"
          top="0"
          left="0"
          mt="5px"
          ml="5px"
        />
        <Box 
          component="h2" 
          ml="80px" 
          color={colors.orangeAccent[200]}
          backgroundColor={colors.primary[100]}
        >
        Skingleam
        </Box>
      </Box>
    </Box>
  );
}

const Item = ({ title, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[200], 
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

export const AppSidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Home");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Sidebar collapsed={isCollapsed} backgroundColor={colors.primary[100]}>
        <Menu 
        iconShape="square" 
        style = {{color: colors.grey[300]}}
        >
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[300],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
              </Box>
            )}
            <IconButton > 
                <MenuOutlinedIcon style = {{color: colors.grey[300]}} />
            </IconButton>
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Home"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Search
            </Typography>

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Chat
            </Typography>
            <Item
              title="Chat 1"
              icon={<KeyboardArrowDownIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Chat 2"
              icon={<KeyboardArrowDownIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Chat 3"
              icon={<KeyboardArrowDownIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h5"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
            </Typography>
            <Item
              title="Guide & FAQ"
              icon={<QuizOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Clear Conversations"
              icon={<DeleteOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Divider variant="middle" style={{ backgroundColor: "grey", margin: "10px 0" }} />

            <Item
              title="Upgrade"
              icon={<DashboardOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Notifications"
              icon={< NotificationsNoneOutlinedIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Settings"
              icon={< SettingsOutlinedIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider variant="middle" style={{ backgroundColor: "grey", margin: "10px 0" }} />
            <Item
              title="Logout"
              icon={< LogoutOutlinedIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default AppSidebar;

